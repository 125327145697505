@tailwind base;
@tailwind components;
@tailwind utilities;

@media (max-width: 640px) {
    .category-overlay {
      y: 0 !important;
    }
  }


  /* For Chrome, Safari, and Edge */
::-webkit-scrollbar {
  width: 12px; /* Set the width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: transparent; /* No background for the track */
}

::-webkit-scrollbar-thumb {
  background-color: gray; /* Gray color for the scrollbar thumb */
  border-radius: 10px; /* Make it rounded */
  border: 3px solid transparent; /* Add transparent border for some padding */
}

/* For Firefox */
* {
  scrollbar-width: thin; /* Make the scrollbar thin */
  scrollbar-color: gray transparent; /* Gray thumb with no background */
}
