@font-face {
    font-family: 'Roboto-Regular';
    src: url('../public/fonts/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
@font-face {
    font-family: 'Prata-Regular';
    src: url('../public/fonts/Prata-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }